export default class Input {
  init() {
    const numberInputs = document.querySelectorAll('input[type="number"]');
    const checkboxInputs = document.querySelectorAll('input[type="checkbox"]');
    const radioInputs = document.querySelectorAll('input[type="radio"]');

    numberInputs.forEach(el => {
      const wrapper = document.createElement('div');
      const increment = document.createElement('button');
      const decrement = document.createElement('button');
      el.insertAdjacentElement('afterend', wrapper);
      wrapper.insertAdjacentElement('afterbegin', el);
      wrapper.insertAdjacentElement('beforeend', increment);
      wrapper.insertAdjacentElement('beforeend', decrement);
      wrapper.classList.add('Input', 'Input--number');
      increment.classList.add('Input__button', 'Input__button--increment');
      decrement.classList.add('Input__button', 'Input__button--decrement');

      increment.addEventListener('click', ev => {
        if (!el.disabled) el.stepUp();
      });

      decrement.addEventListener('click', ev => {
        if (!el.disabled) el.stepDown();
      });
    });

    checkboxInputs.forEach(el => {
      if (el.parentNode.tagName == 'LABEL') el.parentNode.classList.add('Input--checkbox')
    });

    radioInputs.forEach(el => {
      if (el.parentNode.tagName == 'LABEL') el.parentNode.classList.add('Input--radio')
    });
  }
}
