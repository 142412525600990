require("lightgallery.js/dist/js/lightgallery.js");
export default class Lightbox {
  init() {
    const elements = document.querySelectorAll(".ImageGallery--slideshow");
    const lightGalleryItems = document.querySelectorAll(".ImageGalleryItem");
    const controlElement =
      '<svg class="SvgIcon" aria-hidden="true" role="presentation" shape-rendering="geometricPrecision" style="--svg-size: 48px"><use class="icon-chevron-left-icon" xlink:href="/wp-content/themes/toolstrap/public/images/icons.svg#icon-chevron-left-icon"></use></svg>';
    elements.forEach((gallery) => {
      lightGallery(gallery, {
        selector: ".ImageGalleryItem__image",
        counter: false,
        download: false,
        prevHtml: controlElement,
        nextHtml: controlElement,
      });
    });
    lightGalleryItems.forEach((item) => {
      item.addEventListener("click", () => {
        // When the lightbox is opened
        window.parent.dispatchEvent(new CustomEvent("lightbox-open"));
        const lightBox = document.querySelector(".lg-inner");
        const closeButton = document.querySelector(".lg-close");
        const image = document.querySelector(".lg-image");
        const prevButton = document.querySelector(".lg-prev");
        const nextButton = document.querySelector(".lg-next");
        lightBox.addEventListener("click", () => {
          window.parent.dispatchEvent(new CustomEvent("lightbox-close"));
        });
        closeButton.addEventListener("click", () => {
          window.parent.dispatchEvent(new CustomEvent("lightbox-close"));
        });
        image.addEventListener("click", (ev) => {
          ev.stopPropagation();
        });
        prevButton.addEventListener("click", (ev) => {
          ev.stopPropagation();
        });
        nextButton.addEventListener("click", (ev) => {
          ev.stopPropagation();
        });
      });
    });
  }
}