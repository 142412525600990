import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);
export default class Carousel {
  init() {
    var windowSize = window.matchMedia("(max-width: 940px)");
    const elements = document.querySelectorAll(".ImageGrid, .Carousel");
    document.addEventListener("DOMContentLoaded", function () {});
    elements.forEach((element) => {
      const swiper = element.querySelector(".c-content-carousel__swiper");
      const nextArrow = element.querySelector(".Carousel__nav-next");
      const prevArrow = element.querySelector(".Carousel__nav-prev");
      const pagination = element.querySelector(".Carousel__pagination");
      this.swiper = new Swiper(element, {
        slidesPerView: 1,
        loop: false,
        breakpoints: {
          768: {
            slidesPerView: view || 3,
            spaceBetween: 4,
          },
        },
        navigation: {
          nextEl: nextArrow,
          prevEl: prevArrow,
        },
        pagination: {
          el: pagination,
          clickable: true,
        },
      });
      const navCardTitleElement = document.querySelector(".nav-card-title");
      if (navCardTitleElement) {
        // Initialize the text content with the content of the first slide
        const firstSlideTitle =
          this.swiper.slides[0].querySelector(".CardTitle__title").textContent;
        navCardTitleElement.textContent = firstSlideTitle;
        this.swiper.on("slideChange", function () {
          // Get the active slide element
          const activeSlideElement = this.slides[this.activeIndex];
          // Get the content of the CardTitle__title div in the active slide
          const newTitle =
            activeSlideElement.querySelector(".CardTitle__title").textContent;
          navCardTitleElement.classList.add("fade-out");
          // After the transition ends, change the text and start the fade in transition
          setTimeout(() => {
            navCardTitleElement.textContent = newTitle;
            navCardTitleElement.classList.remove("fade-out");
          }, 200);
        });
      }
    });
  }
}