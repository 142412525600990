export default class FilterByLetter {
  init() {
    let servicesArray = [
      "Emergency Services",
      "Robotic Surgery",
      "Women's Services",
      "Heart and Vascular",
      "Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Breast Cancer",
      "Brain and Spine",
      "Breast Cancer",
      "Brain and Spine",
      "Παραδειγματάκι"
    ];

    for (let i = 0; i < servicesArray.length; i++) {

      for (let j = 0; j < document.querySelectorAll(".pt-artists-list-letter").length; j++) {
          if (servicesArray[i].charAt(0) == document.querySelectorAll(".pt-artists-list-letter")[j].textContent) {
            document.querySelectorAll(".items-grid")[j].insertAdjacentHTML(
              "beforeend",
          "<article data-title='" +
            servicesArray[i] +
            "'><div class='pt-artists-items responsive-type-baseline'>" +
            servicesArray[i] +
            "</div></article>"
            )
          }
      }

    }
    //Bold only the letters of services available
    // for (let c = 0; c < document.querySelectorAll("article").length; c++) {
    //   let serviceTitle = document.querySelectorAll("article")[c].textContent;
    //   let firstChar = serviceTitle.charAt(0);

    //   for (let a = 0; a < 26; a++) {
    //     if ( document.querySelectorAll(".alphabet li")[a].textContent == firstChar ) {
    //       document.querySelectorAll(".alphabet li a")[a].style.opacity = "1";
    //     }
    //   }
    // }

    //Filter logic
    // document.querySelectorAll("ul li a").forEach(link =>
    //   link.addEventListener("click", function (e) {
    //   e.preventDefault();
    //   //Get letter that was clicked
    //   let letter = this.textContent;

    //     //loop through cards, find first letter match
    //     for (let j = 0; j < document.querySelectorAll("article").length; j++) {
    //       if (letter == "View all") {
    //         document.querySelectorAll("article")[j].style.display = "block";
    //       } else {
    //         document.querySelectorAll("article")[j].style.display = "none";
    //       }
    //       let serviceTitle = document.querySelectorAll("article")[j].textContent.charAt(0);

    //       if (serviceTitle.indexOf(letter) == 0) {
    //         document.querySelectorAll("article")[j].style.display = "block";
    //       }
    //     }
    //   }
    // )
    // )

    document.querySelectorAll(".lang-toggle").forEach(langButton => {
      langButton.addEventListener("click", function () {
        document.querySelectorAll(".lang-en").forEach(english => {
          english.style.display = window.getComputedStyle(english, null).display === 'none' ? 'block' : 'none';
          }
        )
        document.querySelectorAll(".lang-gr").forEach(greek => {
          greek.style.display = window.getComputedStyle(greek, null).display === 'none' ? 'block' : 'none';
          }
        )
        }
      )
    })
  }
}
