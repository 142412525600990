import Choices from "choices.js"

export default class Dropdown {
  init() {
    const elements = document.querySelectorAll('.Dropdown');

    elements.forEach(el => {
      el.api = new Choices(el, {
        silent: false,
        renderChoiceLimit: -1,
        maxItemCount: -1,
        allowHTML: true,
        delimiter: ',',
        search: false,
        searchEnabled: false,
        position: 'auto',
        resetScrollPosition: true,
        shouldSort: false,
        placeholder: true,
        placeholderValue: null,
        prependValue: null,
        appendValue: null,
        removeItems: true,
        removeItemButton: true,
        renderSelectedChoices: 'always',
        itemSelectText: '',
        classNames: {
          containerOuter: 'choices',
          containerInner: 'choices__inner',
          input: 'choices__input',
          inputCloned: 'choices__input--cloned',
          list: 'choices__list',
          listItems: 'choices__list--multiple',
          listSingle: 'choices__list--single',
          listDropdown: 'choices__list--dropdown',
          item: `choices__item ${el.classList.contains('Dropdown--separator') ? 'choices__item--separated' : ''}`,
          itemSelectable: 'choices__item--selectable',
          itemDisabled: 'choices__item--disabled',
          itemChoice: 'choices__item--choice',
          placeholder: 'choices__placeholder',
          group: 'choices__group',
          groupHeading: 'choices__heading',
          button: 'choices__button',
          activeState: 'is-active',
          focusState: 'is-focused',
          openState: 'is-open',
          disabledState: 'is-disabled',
          highlightedState: 'is-highlighted',
          selectedState: 'is-selected',
          flippedState: 'is-flipped',
          loadingState: 'is-loading',
          noResults: 'has-no-results',
          noChoices: 'has-no-choices'
        },
        // Choices uses the great Fuse library for searching. You
        // can find more options here: https://fusejs.io/api/options.html
        fuseOptions: {
          includeScore: true
        },
        labelId: '',
        callbackOnInit: null,
        callbackOnCreateTemplates: null
      });

      el.addEventListener('choice', (choice) => {
        const valueArray = el.api.getValue();
        const value = choice.detail.choice.value;
        if (valueArray.length && valueArray.filter(i => i.value == value).length)
          setTimeout(() => el.api.removeActiveItemsByValue(value), 100)
      })
    });
  }
}
