export default class Menu {
  init() {
    const menu = document.getElementById("menu");
    const menuMob = document.getElementById("mobile-menu-container");
    const button = document.getElementById("mobile-menu-btn");
    const logo = document.getElementById("navbar-logo");
    const menuLogoLarge = document.querySelector(".menu-logo.mobile");
    const menuLogoSmall = document.querySelector(".menu-logo.tablet");
    const backBtn = document.getElementById("back-btn");
    const langBtn = document.getElementById("lang-menu-btn");
    const searchBtn = document.getElementById("search-menu-btn");
    const searchSection = document.getElementById("search-container");
    const mobileMenu = document.getElementById("mobile-menu");
    const menuButtons = document.querySelectorAll(".menu-item-container");
    const mobileMenuButtons = document.querySelectorAll(
      ".mobile-menu-item-container"
    );
    const menuItems = document.querySelectorAll(".expanding-menu");
    const mobileMenuItems = document.querySelectorAll(".mega-mobile-menu");
    const buttonIcon = button.firstElementChild;
    const espaBanner = document.querySelector(".espa-banner");
    let isActive = false;
    document.addEventListener("click", () => {
      menuButtons.forEach((menuButton) => {
        menuButton.classList.remove("active");
      });
      menuItems.forEach((menuItem) => {
        menuItem.classList.remove("active");
      });
    });
    button.addEventListener("click", (ev) => {
      ev.stopPropagation();
      logo.style.display = "block";
      menu.style.borderRadius = "0 0 0 0";
      mobileMenuButtons.forEach((mobileMenuButton) => {
        mobileMenuButton.classList.remove("active");
      });
      mobileMenuItems.forEach((mobileMenuItem) => {
        mobileMenuItem.classList.remove("active");
      });
      isActive = button.classList.toggle("active");
      if (isActive) {
        mobileMenu.style.display = "flex";
        setTimeout(function () {
          mobileMenu.style.webkitBackdropFilter = "blur(100px)";
        }, 50);
        if (langBtn){
          langBtn.style.display = "inline-grid";
        }
        searchBtn.style.display = "inline-grid";
        menuLogoSmall.style.display = "block";
        menuLogoLarge.style.display = "none";
        button.removeChild(buttonIcon);
      } else {
        menu.style.borderRadius = "0 0 40px 40px";
        mobileMenu.style.display = "none";
        if (langBtn){
          langBtn.style.display = "none";
        }
        searchBtn.style.display = "none";
        searchSection.classList.remove("active");
        backBtn.style.display = "none";
        menuLogoSmall.style.display = "none";
        menuLogoLarge.style.display = "block";
        button.appendChild(buttonIcon);
      }
    });
    backBtn.addEventListener("click", () => {
      backBtn.style.display = "none";
      logo.style.display = "block";
      mobileMenu.classList.toggle("active");
      mobileMenu.style.display = "flex";
      setTimeout(function () {
        mobileMenu.style.webkitBackdropFilter = "blur(100px)";
      }, 50);
      mobileMenuItems.forEach((mobileMenuItem) => {
        mobileMenuItem.classList.remove("active");
      });
      mobileMenuButtons.forEach((buttonColor) => {
        buttonColor.classList.remove("active");
      });
    });
    menuButtons.forEach((menuButton, i) => {
      menuButton.addEventListener("click", (ev) => {
        ev.stopPropagation();
        menuButton.classList.toggle("active");
        menuItems.forEach((menuItem, j) => {
          if (j === i) {
            menuItem.classList.toggle("active");
            if (menuItem.classList.contains("active")) {
              searchSection.classList.remove("active");
            }
          } else {
            menuItem.classList.remove("active");
          }
        });
      });
    });
    mobileMenuButtons.forEach((mobileMenuButton, i) => {
      mobileMenuButton.addEventListener("click", () => {
        mobileMenuButton.classList.toggle("active");
        mobileMenuItems.forEach((mobileMenuItem, j) => {
          if (j === i) {
            mobileMenuItem.classList.toggle("active");
            mobileMenu.classList.remove("active");
            mobileMenu.style.display = "none";
            logo.style.display = "none";
            backBtn.style.display = "flex";
          } else {
            mobileMenuItem.classList.remove("active");
          }
        });
      });
    });
    searchBtn.addEventListener("click", () => {
      searchSection.classList.toggle("active");
      mobileMenu.style.display = "none";
      menuItems.forEach((menuItem) => {
        menuItem.classList.remove("active");
      });
    });
    let oldScroll = 0;
    document.body.addEventListener("scroll", () => {
      const newScroll = window.scrollY || document.body.scrollTop;
      if (
        newScroll > oldScroll + 50 ||
        (document.body.scrollTop > 150 && newScroll > oldScroll)
      ) {
        menuButtons.forEach((menuButton) => {
          menuButton.classList.remove("active");
          if (espaBanner) {
            espaBanner.classList.add("hide-banner");
          }
        });
        menuItems.forEach((menuItem) => {
          menuItem.classList.remove("active");
          if (espaBanner) {
            espaBanner.classList.add("hide-banner");
          }
        });
      }
      oldScroll = newScroll;
    });
  }
}